import Rails from "@rails/ujs"

import 'jquery'
import $ from 'jquery';
global.$ = jQuery;

import('src/plugins') // loads async

import 'css/application'
import 'css/site'
import "@fortawesome/fontawesome-free/css/all.css";

Rails.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
